import {
  FormattedMessage,
  injectIntl
} from "gatsby-plugin-intl";
import React from "react";
import Helmet from "react-helmet";
import Container from "../components/Container";
import {
  circleSmall, waveHorizontalLarge,
  waveVerticalSmall
} from "../components/Images";
import Layout from "../components/Layout";
import Row from "../components/Row";
import styled from "../styling/styled";
import { theme } from "../styling/theme";

const Content = styled.div`
  padding-top: 120px;
  padding-bottom: 60px;
  background-size: 160px 17px, 160px 17px, 17px 17px, 17px 47px;
  background-image: url(${waveHorizontalLarge}), url(${waveHorizontalLarge}),
    url(${circleSmall}), url(${waveVerticalSmall});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 277px) top 90px,
    right calc(${theme.container.background} - 320px) top 150px,
    left calc(${theme.container.background} - 87px) top 43%,
    left calc(${theme.container.background} - 127px) bottom 18%;

  ${[theme.media.mobileMenuVisible]} {
    padding-top: 75px;
    padding-bottom: 30px;
    min-height: 0;
  }
`;

const StyledRow = styled(Row)`
  align-items: flex-start;
  position: relative;
  ${[theme.media.maxM]} {
    display: block;
  }
`;

const Col6 = styled.div`
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
`;

const Spacer = styled.div`
  height: 30px;
  margin-top: 60px;
  margin-bottom: 30px;
  border-top: 1px solid #ddd;
`;


class JobsPage extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <>
        <Helmet
          title={
            intl.formatMessage({ id: "pages.jobs.title" }) + " | Eledio.com"
          }
          meta={[
            {
              property: "og:title",
              content: intl.formatMessage({ id: "pages.jobs.title" })
            }
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <Layout>
          <Content>
            <Container>
              <StyledRow spacing={15}>
                <Col6>
                  <h1>
                    <FormattedMessage id="pages.jobs.title" />
                  </h1>
                  <ul>
                    <li><a href="#iot-product-owner">IoT Product Owner / Manager</a></li>
                    <li><a href="#ui-ux-designer">UX/UI Designer – Web and Mobile Application</a></li>
                  </ul>
                  <Spacer />
                  <h3 id="iot-product-owner">
                    IoT Product Owner / Manager
                  </h3>
                  <h4>
                    Job Mission
                  </h4>
                  <p>
                    We design and develop end-to-end IoT systems. We develop both the hardware and web and mobile applications through which users interact with their connected things. We also develop a cloud backend system.
                  </p>
                  <p>
                    Strict end-user focus is the key principle of our development. The mission of the Product Owner is to bring to the market appealing products and product features, attractive applications, and build a state-of-the-art customer digital interaction system.
                  </p>
                  <h4>
                    Job Role
                  </h4>
                  <p>
                    IoT Product Owner / Manager is a key role in our team. He / she drives the development of Eledio products. On your job you will:
                  </p>
                  <ul>
                    <li>Interact with key clients, partners, power users, collect their demand and feedback</li>
                    <li>Translate client demand into product requirements, develop user stories, define functional requirements</li>
                    <li>Establish and lead end-user testing and user feedback practice</li>
                    <li>Create and manage product road map and feature development pipeline</li>
                    <li>Interact with and coach the product development team, monitor development process</li>
                    <li>Lead the development of our B2B customer engagement system and interactive product documentation</li>
                  </ul>
                  <h4>
                    Candidate Profile
                  </h4>
                  <ul>
                    <li>Experience in digital product design, product ownership, or product management</li>
                    <li>Keen interest and knowledge of IoT technologies, technology savviness</li>
                    <li>Knowledge of digital product design and management process</li>
                    <li>University degree in technical field preferred</li>
                    <li>Good English</li>
                  </ul>
                  <h4>
                    We offer
                  </h4>
                  <ul>
                    <li>You will directly drive the development of attractive products</li>
                    <li>Fast-growing and optimistic company, exciting product area</li>
                    <li>Flexible working schedule</li>
                    <li>Friendly and fair work environment</li>
                    <li>Nice compensation</li>
                    <li>Professional and language education</li>
                    <li>Additional leave, employee benefits</li>
                  </ul>
                  <h4>
                    Additional Information
                  </h4>
                  <p>
                    Location: Brno, other locations in the Czech Republic / home office / flexible<br/>
                    Employment: Full time (employment contract or freelance contract)<br/>
                    Remuneration: on request
                  </p>
                  <p>
                    Please contact David Grundel, <a href="mailto:david.grundel@eledio.com">david.grundel@eledio.com</a>, +420602343440
                  </p>
                  <Spacer />
                  <h3 id="ui-ux-designer">
                    UX/UI Designer – Web and Mobile Applications
                  </h3>
                  <h4>
                    Job Mission
                  </h4>
                  <p>
                    We design and develop end-to-end IoT systems. Our users interact with their connected things through our web and mobile applications. Strict end-user focus is the key principle of our development.
                  </p>
                  <p>
                    The mission of the UI Designer is to create amazing end-user experience by creating appealing, distinctive and easy-to-use user interface for Eledio web and mobile applications.
                  </p>
                  <h4>
                    Job Role
                  </h4>
                  <p>
                    UX/UI Designer plays a key role in creating user experience through which the end users see our products. On your job you will:
                  </p>
                  <ul>
                    <li>Interact with the Product Owner and key clients, collect feedback</li>
                    <li>Collaborate on the development user stories, translate them into interactive design</li>
                    <li>Create and manage consistent UI design patterns</li>
                    <li>Interact with the development team, oversee the UI implementation</li>
                    <li>Participate in end-user testing and research</li>
                  </ul>
                  <h4>
                    Candidate Profile
                  </h4>
                  <ul>
                    <li>Experience in UI/UX design</li>
                    <li>Demonstrable UI portfolio</li>
                    <li>Experience in creating wireframes, user flows, app site maps</li>
                    <li>Preferred technologies: Adobe Photoshop, Adobe Illustrator, Figma</li>
                    <li>Fair English</li>
                  </ul>
                  <h4>
                    We offer
                  </h4>
                  <ul>
                    <li>You will define “the face” on very attractive products</li>
                    <li>Fast-growing and optimistic company, exciting product area</li>
                    <li>Flexible working schedule</li>
                    <li>Friendly and fair work environment</li>
                    <li>Nice compensation</li>
                    <li>Professional and language education</li>
                    <li>Additional leave, employee benefits</li>
                  </ul>
                  <h4>
                    Additional Information
                  </h4>
                  <p>
                    Location: Brno, other locations in the Czech Republic / home office / flexible<br/>
                    Employment: Full time or part time (employment contract or freelance contract)<br/>
                    Remuneration: on request
                  </p>
                  <p>
                    Please contact David Grundel, <a href="mailto:david.grundel@eledio.com">david.grundel@eledio.com</a>, +420602343440
                  </p>
                </Col6>
              </StyledRow>
            </Container>
          </Content>
        </Layout>
      </>
    );
  }
}

export default injectIntl(JobsPage);
